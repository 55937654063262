@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box; }

body {
  background: #272822;
  font-family: 'Montserrat', sans-serif; }

.loading {
  color: #C71585;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  font-size: 11px;
  font-weight: 700; }

.app {
  display: flex;
  padding-left: 200px; }
  .app nav {
    padding: 1rem;
    width: 200px;
    height: 100vh;
    background: #34362e;
    position: fixed;
    left: 0;
    top: 0; }
    .app nav .clear-button {
      background: transparent;
      border: 2px solid #787b68;
      color: #d0d4c4;
      text-transform: uppercase;
      padding: 10px;
      font-weight: bold;
      margin-top: 30px;
      -webkit-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out; }
      .app nav .clear-button:hover {
        border: 2px solid #C71585; }
    .app nav ul {
      list-style: none;
      margin: 0;
      padding: 0; }
      .app nav ul li {
        margin: 10px 0; }
        .app nav ul li a {
          font-size: 12px;
          font-weight: 700;
          color: #d0d4c4;
          cursor: pointer;
          display: flex;
          align-items: center;
          text-decoration: none; }
          .app nav ul li a .color {
            display: block;
            width: 20px;
            height: 12px;
            margin-right: 5px; }
          .app nav ul li a .label {
            position: relative;
            -webkit-transition: all 0.2s ease-in-out;
            transition: all 0.2s ease-in-out;
            left: 0;
            color: #d0d4c4; }
          .app nav ul li a:hover .label, .app nav ul li a.active .label {
            color: #ffffff;
            left: 10px; }
  .app main {
    flex: 1 1;
    padding-top: 1rem; }
    .app main .log-item {
      display: flex;
      border-bottom: 1px solid #676767; }
      .app main .log-item > div {
        padding: 10px; }
      .app main .log-item .date {
        font-size: 11px;
        color: #d0d4c4;
        width: 100px; }
      .app main .log-item .app {
        font-size: 11px;
        font-weight: 700;
        width: 100px; }
      .app main .log-item .log {
        flex: 1 1; }

