@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap');

$bg: #272822;
$white: #d0d4c4;
$whiteHover: #ffffff;
$pink: #C71585;
$navWidth: 200px;
$border: #676767;
* {
  box-sizing: border-box; }

body {
  background: $bg;
  font-family: 'Montserrat', sans-serif; }



.loading {
  color: $pink;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  font-size: 11px;
  font-weight: 700; }

.app {
  display: flex;
  padding-left: $navWidth;

  nav {
    padding: 1rem;
    width: $navWidth;
    height: 100vh;
    background: lighten($bg, 5);
    position: fixed;
    left: 0;
    top: 0;

    .clear-button {
      background: transparent;
      border: 2px solid lighten($bg, 30);
      color: $white;
      text-transform: uppercase;
      padding: 10px;
      font-weight: bold;
      margin-top: 30px;
      transition: all 0.2s ease-in-out;

      &:hover {
        border: 2px solid $pink; } }


    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        margin: 10px 0;
        a {
          font-size: 12px;
          font-weight: 700;
          color: $white;
          cursor: pointer;
          display: flex;
          align-items: center;
          text-decoration: none;

          .color {
            // float: left
            display: block;
            width: 20px;
            height: 12px;
            margin-right: 5px; }

          .label {
            position: relative;
            transition: all 0.2s ease-in-out;
            left: 0;
            color: $white; }

          &:hover,
          &.active {
            .label {
              color: $whiteHover;
              left: 10px; } } } } } }


  main {
    flex: 1 1 0;
    padding-top: 1rem;

    .log-item {
      display: flex;
      border-bottom: 1px solid $border;


      > div {
        padding: 10px; }

      .date {
        font-size: 11px;
        color: $white;
        width: 100px; }


      .app {
        font-size: 11px;
        font-weight: 700;
        width: 100px; }

      .log {
        flex: 1 1 0; } } } }
